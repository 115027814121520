.custom-white-gradient {
    background: linear-gradient(180deg, #FFFFFF 0%, #B4B4B4 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.custom-white-gradient2 {
    background: linear-gradient(180deg, #F9FAFB 0%, #6E6E6E 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.custom-black-gradient {
    background: linear-gradient(180deg, #151515 0%, #373737 0.01%, #000000 100%);

}

.yellow-bottom-gradient {
    position: relative;
    display: inline-block;
    /* Ensure the span behaves like an inline element */
}

.shadow {
    box-shadow: 0px 1px 50px 0px rgba(253, 188, 25, 0.4) // box-shadow: 0px 0px 50px 0px rgba(253, 188, 25, 0.4);
}

.join-line {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 3px;
    width: 100vw;
    background: linear-gradient(92deg, rgba(245, 166, 11, 0.00) 0%, #F5A60B 52.08%, rgba(245, 166, 11, 0.00) 100%);
}

.join-now {
    padding: 8px 12px;
    border-radius: 12px;
    border: 2px solid #454545;
    background: linear-gradient(180deg, #FFC641 0%, #FA7E00 100%);
    box-shadow: 0px 1px 50px 0px rgba(253, 188, 25, 0.40);
    z-index: 2;
}

.fading-bg {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #020911 100%);
}

.flex-container {
    display: flex;
    flex-direction: row;
    height: 200px;
    /* Set the height of the flex container */
}

.yellow-bottom-gradient::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    /* Adjust the height of the gradient border */
    background: linear-gradient(90deg, rgba(245, 166, 11, 0) 0%, #F5A60B 52.08%, rgba(245, 166, 11, 0) 100%);
}

.subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
}

.nav-sidebar-open .wrapper::before {
    opacity: 1;
    visibility: visible;
}

.nav-sidebar-open .wrapper {
    position: fixed;
    transform: translateX(20rem);
    -webkit-transform: translateX(20rem);
    -moz-transform: translateX(20rem);
    -ms-transform: translateX(20rem);
    -o-transform: translateX(20rem);
}

.wrapper {
    position: relative;
    width: 100%;
    min-height: 100%;
    z-index: 2;
    transition: transform .5s ease-in-out;
    -webkit-transition: transform .5s ease-in-out;
    -moz-transition: transform .5s ease-in-out;
    -ms-transition: transform .5s ease-in-out;
    -o-transition: transform .5s ease-in-out;
}

.wrapper:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .75);
    z-index: 400;
    opacity: 0;
    visibility: hidden;
    transition: all .5s ease-in-out;
    -webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
}


.slide-in-left {
    animation-name: slideInLeft;
    animation-duration: 2s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
}

@keyframes slideInLeft {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
}

.slide-in-right {
    animation-name: slideInRight;
    animation-duration: 2s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
}

@keyframes slideInRight {
    from {
        transform: translateX(-50%);
    }

    to {
        transform: translateX(0);
    }
}

.vanish {
    animation-name: vanish;
    animation-duration: 1s;
    /* Adjust duration as needed */
    animation-timing-function: ease-in;
    /* Smoothly disappears */
}

@keyframes vanish {
    from {
        opacity: 1;
        /* Start with full opacity */
    }

    to {
        opacity: 0;
        /* Gradually vanish */
    }
}

.scroll-margin-top-80 {
    scroll-margin-top: 80px;
}

.scroll-margin-top-100 {
    scroll-margin-top: 100px;
}

.slick-dots li button:before {
    color: white !important;
    font-size: 10px !important;
}

ul li {
    list-style: inside;
}